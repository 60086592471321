<template>
  <div>
    <Card>
      <template slot="outer">
        <div class="flex justify-between align-center">
          <div class="py-3 inline-flex align-center">
            <i class="flaticon-video-camera"></i>
            <h3 class="pl-2 text-uppercase">Live Class Archive</h3>
          </div>
        </div>
      </template>
      <CardBody class="p-3">
        <div class="mb-4 flex justify-between justify-xs-center">
          <SelectComponent class="w-64 mr-3" />
        </div>
        <md-divider></md-divider>
        <Table :items="nowPlayings" @onSelect="onSelect">
          <template slot="row" slot-scope="{ item }" md-selectable="single">
            <md-table-cell md-label="SL" md-numeric>{{ item.id }}</md-table-cell>
            <md-table-cell md-label="NAME" class="text-victoria">{{ item.name }}</md-table-cell>
            <md-table-cell md-label="RELATED MATERIALS" class="text-victoria">{{ item.relatedMaterials }}</md-table-cell>
            <md-table-cell md-label="DATE">{{ item.date }}</md-table-cell>
            <md-table-cell md-label="SECTION">{{ item.section }}</md-table-cell>
            <md-table-cell md-label="TIME">{{ item.time }}</md-table-cell>
            <md-table-cell md-label="DURATION">{{ item.duration }}</md-table-cell>
            <md-table-cell md-label="MANAGE BY" class="text-victoria">{{ item.managedBy }}</md-table-cell>
          </template>
        </Table>
      </CardBody>
    </Card>
     <!-- Dialog Start -->
    <Dialog>
        <components :content="content" :is="component"></components>
    </Dialog>
    <!-- Dialog Start -->
  </div>
</template>

<script>
import { Card, CardBody, SelectComponent, Table , Dialog} from "@/components";
import DetailsDialog from "@/components/molecule/live-class/DetailsDialog"
import data from "@/data/live-class/data"
import { mapMutations } from "vuex";

export default {
  name: "Blog-List-View",
  components: {
    Card,
    Table,
    Dialog,
    CardBody,
    DetailsDialog,
    SelectComponent,
  },
  data() {
    return {
      component: 'DetailsDialog',
      content: {},
      nowPlayings: data.nowPlayings
    }
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
    }),
    onSelect(item) {
      this.component = 'DetailsDialog';
      this.content = item;
      this.dialog(true);
    }
  },
};
</script>