<template>
    <div class="p-6" style="width: 650px">
        <img style="width: 100%" src="/images/live-class-preview.jpg" alt="">
        
        <div>
            <h2 class="text-4xl my-6 text-capitalize">Hwo to good at listening</h2>
            <p class="text-uppercase text-victoria text-lg font-bold">MD. Hafizur Rahman</p>

            <div class="text-uppercase text-white bg-victoria rounded inline-block p-4 text-xl font-bold my-3">
                Watch Stream
            </div>
            <div class="inline-block ml-3 inline-flex">
            <p class="text-xl text-uppercase">Related material </p>
            <span class="text-victoria text-lg ml-2">https://asd56werwer</span>
            </div>
        </div>  
    </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  props: {
      content: {
          type: Object
      }
  },
  methods: mapMutations({
      dialog: "setShowDialog",
  })
}
</script>